'use client'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dialog, Navigation, BottomSheet } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'
import type { CloseTarget } from '@vinted/web-ui/dist/types/constants/portal'

import { actions } from 'state/auth/slice'
import { AuthView } from 'state/auth/constants'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useTranslate from 'hooks/useTranslate'
import useBreakpoint from 'hooks/useBreakpoint'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'

import Auth from '../Auth'
import useAuthTracking from '../hooks/useAuthTracking'

const AuthModal = () => {
  const dispatch = useDispatch()
  const cookies = useCookie()
  const translate = useTranslate('auth')
  const breakpoints = useBreakpoint()
  const { trackClickEvent } = useAuthTracking()
  const { isAuthModalOpen, closeAuthModal } = useAuthModal()

  useEffect(() => {
    if (!isAuthModalOpen) return

    dispatch(
      actions.setAuthView({
        authView: cookies.get(cookiesDataByName.last_user_id)
          ? AuthView.SelectTypeLogin
          : AuthView.SelectTypeRegister,
      }),
    )
  }, [cookies, dispatch, isAuthModalOpen])

  const handleButtonClose = () => {
    trackClickEvent({ target: ClickableElement.CloseSelectTypeModal, targetDetails: 'button' })

    closeAuthModal()
  }

  const handleModalClose = (closeTarget: CloseTarget) => {
    trackClickEvent({
      target: ClickableElement.CloseSelectTypeModal,
      targetDetails: closeTarget === Dialog.CloseTarget.CloseIcon ? 'button' : 'overlay',
    })

    closeAuthModal()
  }

  if (!isAuthModalOpen) return null

  if (breakpoints.phones) {
    return (
      <BottomSheet
        isVisible
        onClose={handleModalClose}
        closeButtonEnabled
        initialHeight={BottomSheet.Height.FullHeight}
        testId="auth-bottom-sheet"
      >
        <div className="u-ui-padding-top-x-large">
          <Auth />
        </div>
      </BottomSheet>
    )
  }

  return (
    <Dialog
      testId="auth-modal"
      show
      defaultCallback={handleModalClose}
      closeOnOverlay
      hasScrollableContent
      className="auth__container"
      aria={{
        labelledby: 'auth_modal_title',
      }}
    >
      <div className="u-fill-width">
        <Navigation
          right={
            <Button
              iconName={X24}
              inverse
              inline
              onClick={handleButtonClose}
              aria={{ 'aria-label': translate('a11y.close') }}
            />
          }
        />
        <div className="u-overflow-auto" aria-live="polite" aria-relevant="additions">
          <Auth />
        </div>
      </div>
    </Dialog>
  )
}

export default AuthModal
